<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('add_activation')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <form>
                    <v-text-field v-model="editedData.activation"
                                  :label="$t('activation')"
                                  required
                                  dense
                                  outlined
                                  :error-messages="activationErrors"
                                  @input="$v.editedData.activation.$touch()"
                                  @blur="$v.editedData.activation.$touch()"
                    ></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editDivisionForm",
        props: ["success"],
        computed: {
            activationErrors() {
                const errors = [];
                if (!this.$v.editedData.activation.$dirty) return errors;
                !this.$v.editedData.activation.required &&
                errors.push("From is required");
                return errors;
            },
        },
        data() {
            return {
                selectData: {
                    destinations: [],
                    selectedValue: null
                },
                destinations: [],
                province: [],

                editedData: {
                    destination: "",
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };

        },
        validations: {
            editedData: {
                activation: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        activation: this.editedData.activation,
                    };
                    axios.post("TourPackageBusiness/InsertIntoTourPackageBusinessActivationAsync", param).then(response => {
                        console.log({response})
                        if (response.data.success) {
                            this.$v.$reset();
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Activation added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"

                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.activation = "";
            }
        }
    };
</script>

<style scoped>
</style>